<div class="navbar">
    <div class="navbar-container">
        <div class="brand">
            <img class="image-brand" src="../../assets/images/png/Logo Hunter (sem fundo).png" alt="Hunter's logo">
            <span>MY TEMPLATE</span>
        </div>
        <div class="portfolio">
            <span>by: <a href="https://edsoncamargo.dev" target="_blank"
                    rel="noopener noreferrer">edsoncamargo.dev</a></span>
        </div>
    </div>
</div>